<template>
  <div class="lecturer">
    <div class="content-box  examCont"  v-if="commonExamList.length >0">
        <div class="content">
          <ul>
            <li
              v-for="item in commonExamList"
              :key="item.id"
              @click="goExam(item)"
            >
              <p class="exam_name">{{item.exam_name}}</p>  
              <div class="time_">
                <p>开始时间：{{item.start_date}}</p>
                <p>结束时间：{{item.end_date}}</p>
              </div>     
              <div class="down_num">
                限制考试次数：{{item.limit_desc}}
              </div> 
              <div class="btn">去考试</div>    
            </li>
          </ul>
        </div>
      </div>
  
    <pagination v-if="num>0" :num="num" :limit="limit" @getNew="getNew"></pagination>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'lecturer',
  data(){
    return {
      num: 0,            //数据总个数
      limit: 12,         //一页显示几个
      nowPage: 1, //当前页码     
      commonExamList:[],//公开考试列表
    }
  },
  components:{
    pagination
  },
  mounted(){
    this.getOpenExam();      //获取讲师列表
  },
  methods: {
    //获取讲师列表
    getOpenExam(){
      this.$axios.post(`/v1/commonExam/list`,{page:this.nowPage,per_page:this.limit}).then(res=>{
        if(res.code==0){
          this.commonExamList = res.data.data
          this.num = res.data.total
        }
      })
    },
    // 跳转到考试
    goExam(item){
        this.$router.push('/commonExam/text/'+item.id)
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getOpenExam();
    },
  }
}
</script>
<style scoped lang="scss">
.examCont{
    width: 1200px;
    margin: 40px auto;
  ul{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 30%;
      background: #fff;
      box-shadow: 0px 0px 14px 1px rgba(0, 12, 40, 0.14);
      border-radius: 6px;
      box-sizing: border-box;
      padding:15px;
      margin-right: 2%;
      margin-bottom: 20px;
      p.exam_name{
        font-size: 16px;
        margin-bottom: 10px;
      }
      div.time_{
        p{
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
        }
      }
      .down_num{
        font-size: 14px;
        color: #666;
      }
      div.btn{
        display: inline-block;
        background: #254ed4;
        padding: 5px 10px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
.page-wrap{
  padding-bottom: 30px;
}
.more_btn{
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
  margin-top: 20px;
}
</style>